import React, { useEffect, useState } from "react";
import Introduction from "./introduction/Introduction.js";
import Pricing from "./pricing/Pricing.js";
import JourneyProcess from "./journeyProcess/JourneyProcess.js";
import MeetCounsellors from "./meetCounsellors/MeetCounsellors.js";
import Benefits from "./benefits/Benefits.js";
import RecommendedReading from "./recommendedReading/RecommendedReading.js";
import StartJourneyWithUs from "./startJourneyWithUs/StartJourneyWithUs.js";
import Publicity from "./publicity/Publicity.js"
import Features from "./features/Features.js";
import Footer from "./footer/Footer.js";
import AppStoreButtons from "./appStorebuttons/AppStoreButtons"

import "../../../utils/layout.css";
import "../../../utils/constants.css";

import "./landingPage.css";

const LandingPage = (props) => {
  return (
    <div className="flex-column-start-center">
      <Introduction />
      <Publicity/>
      <Benefits />
      <Pricing id="pricing" />
      <MeetCounsellors />
      <JourneyProcess id="how-does-it-work" />
      <Features />
      <RecommendedReading />
      <AppStoreButtons/>
      <Footer />
    </div>
  );
};

export default LandingPage;
