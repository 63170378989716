import React, { useEffect, useState } from "react";

import { CheckOutlined } from "@ant-design/icons";
import "../../../../utils/layout.css";
import "../../../../utils/constants.css";
import AppStoreButtons from "../appStorebuttons/AppStoreButtons"

import "./benefits.css";

const Benefits = (props) => {
  const publicDir = process.env.PUBLIC_URL;
  const benefitPhone = publicDir + "/assets/images/benefits/benefit.png";
  return (
    <div
      id={props.id && props.id}
      className={`flex-row-center-center benefits-wrapper ${props.className}`}
    >
      <img className="benefits-image" src={benefitPhone} />
      <div className="flex-column-center-start benefits-text">
        <div className="flex-row-start-center font-24-900 benefits-header">
          Benefits of Steady
        </div>
        {[
          "Eliminate commute time and scheduling hassles",
          "Flexible communication, text whenever you want",
          "Save money while recieving quality therapy",
          "No awkwardness, have the space you need to think",
        ].map((e) => (
          <div className="font-18-500">{e}</div>
        ))}
      </div>
    </div>
  );
};

export default Benefits;
