import React, { useEffect, useState } from "react";

import "../../../../utils/layout.css";
import "../../../../utils/constants.css";

import "./footer.css";
import { LinkedinOutlined, InstagramOutlined } from "@ant-design/icons";

const Footer = (props) => {
  const publicDir = process.env.PUBLIC_URL;
  const logoImagePath = publicDir + "/assets/images/logo.png";

  return (
    <div
      id={props.id && props.id}
      className="flex-row-center-center footer-wrapper"
    >
      <div className="footer-content-wrapper flex-row-space-around-center">
        <img src={logoImagePath} className="footer-logo" />
        <div className="flex-column-center-start footer-links">
          <a href="mailto:hello@heysteady.com">Contact Us</a>
          <a href="/terms-of-use">Terms & conditions</a>
          <a href="/privacy-policy">Privacy Policy</a>
        </div>
        <div className="flex-column-center-start footer-social">
          <div className="flex-row-start-center footer-social-text">
            Follow us
          </div>
          <div className="flex-row-start-center footer-social-buttons">
            <a
              href="https://www.linkedin.com/company/heysteady"
              rel="noopener noreferrer"
              target="_blank"
            >
              <LinkedinOutlined className="footer-social-icon" />
            </a>
            <a
              href="https://instagram.com/heysteadysg"
              rel="noopener noreferrer"
              target="_blank"
            >
              <InstagramOutlined className="footer-social-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
