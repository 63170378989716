import React, { useEffect, useState } from "react";
import { DatePicker, Table, Tag, Spin } from "antd";
import { getCharges } from "../../../services/charges";
import { useAdminContext } from "../../../providers/AdminContext";
import moment from "moment";

const AdminLogin = (props) => {

    let [breakdown, setBreakdown] = useState({})
    let [isLoading, setLoading] = useState(false)

    const onPickMonth = (date, dateString) => {
        setLoading(true)
        const startEpochTimeUtc = moment.utc(date).startOf('month').unix()

        const endEpochTimeUtc = moment.utc(date).endOf('month').unix()


        getCharges(startEpochTimeUtc, endEpochTimeUtc).then((res) => {
            setBreakdown(res.data["therapist_breakdown"])
            setLoading(false)
        }).catch(e=>{
            if(e.response.status === 401){
              window.location = "/admin"
            }
            setLoading(false)
          })
    }

    const therapists_columns = [
        {
            title: 'Therapist',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Total Deducted',
            dataIndex: 'total_deducted',
            key: 'total_deducted',
        },
        {
            title: 'Total Stripe Fees',
            dataIndex: 'stripe_fees',
            key: 'stripe_fees',
        },
        {
            title: 'Total Owed',
            dataIndex: 'total_owed',
            key: 'total_owed',
        },
    ]

    const charges_columns = [
        {
            title: "Date Invoiced",
            dataIndex: 'date_invoiced',
            key: 'date_invoiced',
        },
        {
            title: "Date Charged",
            dataIndex: 'date_charged',
            key: 'date_charged',
        },
        {
            title: 'Customer',
            dataIndex: 'customer_name',
            key: 'customer_name',
        },
        {
            title: 'Period Start',
            dataIndex: 'period_start',
            key: 'period_start',
        },
        {
            title: 'Period End',
            dataIndex: 'period_end',
            key: 'period_end',
        },
        {
            title: 'Discount Name',
            dataIndex: 'promo_codes',
            key: 'promo_codes',
        },
        {
            title: 'Original Price',
            dataIndex: 'total_price',
            key: 'total_price',
        },
        {
            title: 'Discount',
            dataIndex: 'amount_discounted',
            key: 'amount_discounted',
            render: amount_discounted => (
                <>
                    {
                        amount_discounted != 0 ?
                        <Tag color='volcano' key={amount_discounted}>
                        {`-${amount_discounted}`}
                    </Tag>
                    : <></>
                    }
                     

                </>
            ),
        },
        {
            title: 'Total Charged',
            dataIndex: 'amount_charged',
            key: 'amount_charged',
        },
        {
            title: 'Deducted Fees',
            dataIndex: 'fees_deducted',
            key: 'fees_deducted',
        },
        {
            title: 'Stripe Fees',
            dataIndex: 'stripe_fees',
            key: 'stripe_fees',
        },
        {
            title: 'Fees owed',
            dataIndex: 'fees_owed',
            key: 'fees_owed',
        },
        {
            title: 'Description',
            dataIndex: 'charge_desc',
            key: 'charge_desc',
        },
        {
            title: 'Status',
            dataIndex: 'refunded',
            key: 'refunded',
            render: refunded => (
                <>

                    {refunded ? <Tag color='volcano' key={refunded}>
                        Refunded
                    </Tag> : <Tag color='geekblue' key={refunded}>
                        Paid
                    </Tag>}

                </>
            ),
        },
    ];

    return (
        <div
            className="flex-column-start-center"
            style={{ width: "100%", padding: "40px" }}
        >
            <DatePicker onChange={onPickMonth} picker="month" />
            {
                isLoading ? <Spin size="large" style={{marginTop: "40px"}} /> : <></>
            }
            {
                Object.keys(breakdown).length > 0 ?
                        <div class="flex-column-start-center" style={{ width: "100%" }}>

                            {
                                 Object.keys(breakdown).map(key => {
                                    return <div style={{
                                        width: "100%", marginTop: "20px", marginBottom: "20px", 
                                    }}>
                                        <div className="flex-row-start-center" style={{ width: "100%", fontSize: "40px" }}>
                                            <b>{breakdown[key].name}</b>
                                        </div>
                                        <Table dataSource={[breakdown[key]]} columns={therapists_columns} />
                                        <Table dataSource={breakdown[key].charges} columns={charges_columns} />
                                    </div>
                                })

                            }
                        </div> 

                    : <></>
            }
        </div>
    );
};

export default AdminLogin;
