import axios from './axiosClient';
import { ApiURL } from "../utils/constants";

const ping = async () => {
    return axios.get(`${ApiURL}/users/ping`);
  };

const getSubscribedUsers = async (query = null, limit) => {
  return axios.get(`${ApiURL}/admin/subscribed-users`, {
    params: {
      query: query,
      from_range: limit - 5, 
      to_range: limit,
    },
  });
};

const getUserOnboardingData = async (userId) => {
  return axios.get(`${ApiURL}/admin/user-records`, {
    params: {
      user_id: userId,
    },
  });
};

const getTherapists = async (query = null, limit) => {
  return axios.get(`${ApiURL}/admin/therapists`, {
    params: {
      query: query,
      from_range: limit - 5, 
      to_range: limit,
    },
  });
};

const matchPatient = async (patientId, therapistId) => {
  return axios.post(`${ApiURL}/admin/match-patient`, {
    patient_id: patientId,
    therapist_id: therapistId,
  });
};

export { ping, getSubscribedUsers, getUserOnboardingData, getTherapists, matchPatient };
