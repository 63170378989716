import React, { useEffect, useState, useRef, useMemo } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Button } from "antd";

import "../../utils/layout.css";
import "../../utils/constants.css";
import "./button.css";


const Navbar = (props) => {

    let {
        onClick,
        icon,
        text, 
        className
    } = props

  return (
    <Button
        className = {`flex-row-center-center rounded-button ${className}`}
        onClick={onClick}
        type="primary"
        icon = {icon ? icon : null}
    >
        {
            text
        }    
    </Button>
  );
};

export default Navbar;


// transform: translateY(0px) translateX(0px); transition: transform 400ms ease 0s;
