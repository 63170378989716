import React, {useEffect, useState} from "react";
import "../../utils/layout.css";
import "../../utils/constants.css";
import qs from 'qs';
import ForgetPassword from "./forgetPassword/ForgetPassword";
import ExpiredLink from "./expiredLink/ExpiredLink";
import EmailVerified from "./emailVerified/EmailVerified";

const Auth = () => {

  let [ urlType, setUrlType ] = useState(null)
  let [ errorCode, setErrorCode ] = useState(null)
  let [ accessToken, setAccessToken ] = useState(null);

  useEffect(
    ()=>{
      const hashString = window.location.hash.substring(1);
      const values = qs.parse(hashString); 
      setUrlType(values["type"]);
      setErrorCode(values["error_code"]);
      setAccessToken(values["access_token"]);
    },[window]
  )

  if (errorCode === "404") {
    return <ExpiredLink/>;
  } else if (urlType === "signup") {
    return <EmailVerified/>;
  } else if (urlType === "recovery") {
    return <ForgetPassword accessToken={accessToken} />;
  } else{
    return <div/>
  }
};

export default Auth;
