import React, { useEffect, useState } from "react";

import "../../../../utils/layout.css";
import "../../../../utils/constants.css";

import "./publicity.css";

const Publicity = (props) => {
  const publicDir = process.env.PUBLIC_URL;
  const straitsTimeImage = publicDir + "/assets/images/publicity/straitstime.png";
  const power98 = publicDir + "/assets/images/publicity/power98.png";
  const cna = publicDir + "/assets/images/publicity/cna.png";
  const channel5 = publicDir + "/assets/images/publicity/channel5.jpg";

  return (
    <div
      id={props.id && props.id}
      className="flex-column-center-center font-24-900"
    >
        As featured on

        <div
            className="flex-row-center-center logo-row-container"
        >
                <img className="publicity-logo" src={power98} />
                <img className="publicity-logo" src={cna} />
                <img className="publicity-logo" src={channel5} />
                <img className="st-logo" src={straitsTimeImage} />
            
            
        </div>

    </div>
  );
};

export default Publicity;
