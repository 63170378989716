import { Redirect } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import {useUpdateAdminContext} from "../../../providers/AdminContext"
import { login } from "../../../services/auth";
import "../../../utils/layout.css";
import "../../../utils/constants.css";

const AdminLogin = (props) => {

    const setToken = useUpdateAdminContext();
    let [isLoggingIn , setLoggingIn] = useState(false)

    const onFinish = (values) => {
        const username = values.username
        const password = values.password
        setLoggingIn(true)
        login(username, password).then(e=>{
            const data = e.data
            if(data["success"]){    
                setToken(data.token)
                localStorage.setItem("token", data.token)
                props.history.push('/admin/match-patients')
            }
            setLoggingIn(false)
        }).catch(e=>setLoggingIn(false))
    };

  return (
    <div
      className="flex-column-start-center"
      style={{ width: "100%", padding: "20px" }}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" disabled={isLoggingIn}>
            {
                isLoggingIn ? "logging in" : "Login"
            }
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminLogin;
