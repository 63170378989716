import axios from './axiosClient';
import { ApiURL } from "../utils/constants";

const getCharges = async (start_utc_epoch_time, end_utc_epoch_time) => {
    return axios.get(`${ApiURL}/admin/therapists/billing`, {params:{
        start_utc_epoch_time: start_utc_epoch_time,
        end_utc_epoch_time: end_utc_epoch_time,
    }});
  };

export  {
    getCharges
}