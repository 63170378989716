import React, { useEffect, useState, useRef } from "react";
import CountUp from "react-countup";
import "../../../../utils/layout.css";
import "../../../../utils/constants.css";
import { getLandingPageInfo } from "../../../../services/messageCounter";
import AppStoreButtons from "../appStorebuttons/AppStoreButtons"
import "./introduction.css";

import Navbar from "../../../../components/navbar/Navbar.js";
import useOnScreen from "../../../../hooks/useOnScreen.js";
import WaitListInput from "../../../../components/waitlistInput/WaitlistInput";

const Introduction = (props) => {
  const publicDir = process.env.PUBLIC_URL;
  const logoImagePath = publicDir + "/assets/images/logo.png";
  const intro1 = publicDir + "/assets/images/introduction/intro-1.png";
  const intro2 = publicDir + "/assets/images/introduction/intro-2.png";
  const intro3 = publicDir + "/assets/images/introduction/intro-3.png";
  const backgroundGirlImage =
    "https://images.pexels.com/photos/4429297/pexels-photo-4429297.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260";

  const ref = useRef();
  let [count, setCounter] = useState(10);

  useEffect(() => {
    getLandingPageInfo()
      .then((res) => {
        if (res.data && res.data.data && res.data.data["message_count"]) {
          setCounter(Math.floor(res.data.data["message_count"]));
        }
      })
      .catch((e) => setCounter(10));
  }, []);

  return (
    <>
      <img className="logo" src={logoImagePath} />
      <Navbar />

      <div
        id={props.id && props.id}
        className="intro-title flex-column-center-center"
        style={{
          backgroundImage: `url(${backgroundGirlImage})`,
          // backgroundImage: `url(${intro1}), url(${intro2}), url(${intro3})`,
          // backgroundPosition: "left, center, right",
          // backgroundRepeat: "no-repeat, no-repeat, no-repeat",
          // backgroundSize: "33% 100%, 34% 100%, 33% 100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "left 30% center",
        }}
      >
        <div className="intro-header font-48-800" style={{ color: "white" }}>
          <text>
            Affordable, safe and convenient chat therapy for young people.
          </text>
        </div>

        <div className="overlay" />
      </div>
      <div className="flex-row-center-center font-18-500 counter-bottom">
        <text>
          🎉 Over{" "}
          <CountUp
            className=""
            start={count - 10}
            end={count}
            duration={1}
            redraw={false}
          />{" "}
          messages have been exchanged on Steady.
        </text>
      </div>
        <AppStoreButtons/>
      {/* 
      <div className="flex-row-center-center">
        <WaitListInput />
      </div> */}
    </>
  );
};

export default Introduction;
