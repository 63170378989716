import React, { useEffect, useState } from "react";

import { CheckOutlined } from "@ant-design/icons";
import "../../../../utils/layout.css";
import "../../../../utils/constants.css";

import "./appStoreButtons.css";

const AppStoreButtons = (props) => {
  const publicDir = process.env.PUBLIC_URL;

  const appStore = publicDir + "/assets/images/introduction/appstore.png";
  const playStore = publicDir + "/assets/images/introduction/playStore.png";

  return (
    <div
      id={props.id && props.id}
      className={`flex-column-center-center ${props.className}`}
      style={{
          width: "100%",
          marginTop: "20px",
          marginBottom: "40px"
      }}
    >
      <text className="flex-row-center-center font-24-900 button-header">
          Download the app and create a free account now
        </text>
        <div className={`store-buttons flex-row-center-center ${props.additionalStoreButtonClass}`}>
          <a target="_blank" href="https://apple.co/3sKDbU4">
            <img className="store-image-ios" src={appStore} />
          </a>
          <a target="_blank" href="https://bit.ly/steady-play-store">
            <img className="store-image-android" src={playStore} />
          </a>
        </div>
    </div>
  );
};

export default AppStoreButtons;