import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../utils/layout.css";
import "../../../utils/constants.css";
import { ApiURL } from "../../../utils/constants";

const EmailChange = (props) => {
  const emailChangeToken = props.emailChangeToken;
  const publicDir = process.env.PUBLIC_URL;
  const logoImagePath = publicDir + "/images/logo.png";
  const waveImagePath = publicDir + "/images/wave.png";

  let [isLoading, setLoadingStatus] = useState(true);
  let [error, setError] = useState(false);

  useEffect(async () => {
    axios
      .post(`${ApiURL}/accounts/therapist-email-change`, {
        email_change_token: emailChangeToken,
      })
      .then((res) => {
        setLoadingStatus(false);
        setError(false);
      })
      .catch((e) => {
        setLoadingStatus(false);
        setError(true);
      });
  }, []);

  return (
    <div className="fullscreen flex-column-start-center">
      <img className="logo-image" src={logoImagePath} />
      <div
        className="primary-font"
        style={{ fontSize: 25, padding: 10, textAlign: "justify" }}
      >
        {!isLoading
          ? error
            ? "An error has occured"
            : "Your email has been verfied. Proceed to the app."
          : "Verifying email address..."}
      </div>
      <img className="wave-image" src={waveImagePath} />
    </div>
  );
};

export default EmailChange;
