import axios from "axios";
import { ApiURL } from "../utils/constants";

const login = async (email, password) => {
  return axios.post(`${ApiURL}/admin-auth/login`, {
    email: email,
    password: password,
  });
};

export { login };
