import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { getSubscribedUsers, getTherapists, matchPatient } from "../../../services/users";
import SearchUsersInput from "./SearchUsersInput";
import PatientCardInfo from "./PatientInfoCard";
import TherapistCardInfo from "./TherapistCardInfo"
import { useAdminContext } from "../../../providers/AdminContext";

import "../../../utils/layout.css";
import "../../../utils/constants.css";
import "./matchPatients.css";

const MatchPaitents = (props) => {
  const adminContext = useAdminContext();
  let [selectedPatient, setSelectedPatient] = useState(null);
  let [selectedTherapist, setSelectedTherapist] = useState(null);
  let [isMatching, setMatching] = useState(false)
  let [isMatched , setMatched] = useState(false)

  let matchPatientAndTherapist = () => {
    setMatching(true)
    matchPatient(selectedPatient.id, selectedTherapist.id, adminContext.token).then(e=>{
      if(e.data["success"]){
        setMatched(true)
        setSelectedPatient(e.data["updated_patient"])
      }else{
        props.history.push('/admin')
      }
      setMatching(false)
    }).catch(e=>{
      if(e.response.status === 401){
        window.location = "/admin"
      }
      setMatching(false)
    })
  }
  return (
    <div
      className="flex-column-start-center"
      style={{ width: "100%", padding: "20px" }}
    >
      <div className="flex-row-start-center">
        <div style={{ padding: "20px" }}> Patient: </div>
        <SearchUsersInput placeholder={"Select Patient"} fetchDataFunc={getSubscribedUsers} setSelectedValue={setSelectedPatient} />
      </div>
      <PatientCardInfo user={selectedPatient} />

      <div className="flex-row-start-center">
        <div style={{ padding: "20px" }}> Therapists: </div>
        <SearchUsersInput placeholder={"Select Therapist"} fetchDataFunc={getTherapists} setSelectedValue={setSelectedTherapist} />
      </div>
      <TherapistCardInfo therapist={selectedTherapist} />
      <Button type="primary" style={{margin:"20px"}} disabled={selectedPatient == null || selectedTherapist == null || isMatching || selectedPatient!=null && selectedTherapist!=null && "therapists" in selectedPatient && selectedPatient["therapists"].includes(selectedTherapist["id"])} onClick={matchPatientAndTherapist} >
        {
          isMatching ? "Matching..." : selectedPatient!=null && selectedTherapist!=null && "therapists" in selectedPatient && selectedPatient["therapists"].includes(selectedTherapist["id"]) ? "Already Matched" : "Match"
        }
      </Button>
    </div>
  );
};

export default MatchPaitents;
