import React from "react";
import { qs } from "url-parse";

import "../../utils/layout.css";
import "../../utils/constants.css";

import EmailChange from "./emailChange/EmailChange.js";
import LandingPage from "./landingPage/LandingPage.js";

const Home = (props) => {

  const queryString = qs.parse(props.location.search, { ignoreQueryPrefix: true })
  const emailChangeToken = queryString.email_change_token

  if(queryString && emailChangeToken){
    return <EmailChange emailChangeToken={emailChangeToken}/>
  }else{
    return <LandingPage/>
  }
};

export default Home;
