import React, { useEffect, useState } from "react";
import { Descriptions, Collapse, Checkbox } from "antd";
import { getUserOnboardingData } from "../../../services/users";
import { useAdminContext } from "../../../providers/AdminContext";
import "../../../utils/layout.css";
import "../../../utils/constants.css";
const { Panel } = Collapse;

const TherapistCardInfo = ({ therapist }) => {

  return (
    <Collapse style={{ width: "100%" }}>
      <Panel
        header={
          therapist != null
            ? `${therapist["first_name"]}'s Info`
            : "No Therapist Info"
        }
        key="1"
      >
        {therapist != null && Object.keys(therapist).length > 0 ? (
          <>
            <Descriptions
              bordered
              column={1}
              layout="vertical"
              style={{ margin: "10px" }}
              title="Therapist data"
            >
              <Descriptions.Item key={therapist["id"]} label={"user_id"}>
                {therapist["id"]}
              </Descriptions.Item>
              <Descriptions.Item key={therapist["first_name"]} label={"name"}>
                {therapist["first_name"]}
              </Descriptions.Item>
            </Descriptions>

            {Object.keys(therapist["data"]).map((key) => (
              <Descriptions
                key={key}
                bordered
                column={1}
                layout="vertical"
                style={{ margin: "10px" }}
                title={key}
              >
                {therapist["data"][key].map((e) => (
                  <Descriptions.Item>{e}</Descriptions.Item>
                ))}
              </Descriptions>
            ))}
          </>
        ) : (
          <></>
        )}
      </Panel>
    </Collapse>
  );
};

export default TherapistCardInfo;
