import React from "react";

import "../../utils/layout.css";
import "../../utils/constants.css";

import "./payment.css";

const Payment = () => {
  const publicDir = process.env.PUBLIC_URL;
  const paymentQrCode = publicDir + "/assets/images/paynow_qr.jpg";
  const waveImagePath = publicDir + "/assets/images/wave.png";

  return (
    <div className="fullscreen flex-column-start-center">
      <div
        className="primary-font payment-text"
        style={{
          fontSize: 25,
          padding: 10,
          textAlign: "justify",
          fontWeight: 600,
        }}
      >
        $26 for 1 week or $67 for 4 weeks with a trained counsellor
      </div>
      <img className="qr-code" src={paymentQrCode} />
      <div
        className="primary-font payment-text"
        style={{ fontSize: 20, padding: 10, textAlign: "justify" }}
      >
        To Pay, either scan the QR code directly or <br />
        1. Screenshot this QR code <br />
        2. Upload to PayNow on your banking app <br />
        <br />
        We're only able to accept PayNow for the time being- sorry for any
        inconvenience! <br />
        <br />
        Let us know through the Steady Support chat once the payment is
        processed.
      </div>
      <img className="wave-image-payment" src={waveImagePath} />
    </div>
  );
};

export default Payment;
