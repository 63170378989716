import React, { useEffect, useState, useRef, useMemo } from "react";
import { Input, Button } from "antd";

import "../../utils/layout.css";
import "../../utils/constants.css";
import { insertToEarlySubmissions } from "../../services/emailWaitlist.js";

import "./waitlist.css";

const { Search } = Input;

const WaitListInput = (props) => {
  let [email, setEmail] = useState("");
  let [emailError, setEmailError] = useState(false);

  const onChangeInput = (e) => {
    setEmail(e.target.value.trim());
  };

  const onSubmitEmail = (e) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = emailRegex.test(String(email));
    if (email.length > 0 && isValidEmail) {
      insertToEarlySubmissions(email)
        .then(() => {
          setEmailError(false);
          window.location.assign("https://bit.ly/3fE0nxK");
        })
        .catch(() => {
          setEmailError(true);
        });
    } else {
      setEmailError(true);
    }
  };

  return (
    <div className="flex-column-center-center">
      <div
        className={`waitlist-error flex-row-center-center ${
          emailError ? "waitlist-error-colored" : ""
        }`}
      >
        {emailError && "Please input a valid email"}
      </div>
      <div className="waitlist-wrapper">
        <Input
          className="waitlist-input"
          placeholder="Email"
          allowClear
          enterButton="Get early access"
          size="middle"
          bordered={true}
          onChange={onChangeInput}
        />
        <Button className="waitlist-button font-18-500" onClick={onSubmitEmail}>
          Get early access
        </Button>
      </div>
    </div>
  );
};

export default WaitListInput;

// transform: translateY(0px) translateX(0px); transition: transform 400ms ease 0s;
