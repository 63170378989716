import React, { useEffect, useState } from "react";

import { CheckOutlined } from "@ant-design/icons";

import "../../../../utils/layout.css";
import "../../../../utils/constants.css";

import "./meetCounsellors.css";

const MeetCounsellors = (props) => {
  const publicDir = process.env.PUBLIC_URL;
  const andrew = publicDir + "/assets/images/counsellor-quotes/andrew.jpg";
  const feiyue = publicDir + "/assets/images/counsellor-quotes/feiyue.png";
  const jamescook =
    publicDir + "/assets/images/counsellor-quotes/jamescook.png";
  const wave = publicDir + "/assets/images/meet-counsellor/wave.svg";

  const counsellorImage = andrew;
  const qualificationImages = [feiyue, jamescook];
  const qualifications = [
    "Resilience Coaching",
    "Leadership Guidance",
    "Psychological Interventions",
  ];

  return (
    <div
      id={props.id && props.id}
      className="flex-column-start-center meet-counsellor-wrapper"
    >
      <div className="flex-row-center-center meet-counsellor-header font-24-900">
        Our counsellors
      </div>
      <div
        className="flex-row-space-around-center meet-counsellor-policies-wrapper"
        
      >
        <img className="background-image" src={wave} />
        <div className="flex-row-center-center counsellor-image-desc-wrapper">
          <div className="flex-row-center-center counsellor-image-wrapper">
            <div className="counsellor-image-shadow-one" />
            <div className="counsellor-image-shadow-two" />
            <div
              className="counsellor-image-top"
              style={{
                backgroundImage: `url(${counsellorImage})`,
              }}
            />
          </div>

          <div className="flex-column-center-start counsellor-desc-wrapper">
            <div className="font-18-500">Andrew Raphael</div>
            <div className="font-14-500">
              Previously Fei-Yue Mental Health Counsellor
            </div>
            {/* <div className="flex-row-center-center counsellor-qualifications-image-wrapper">
              {qualificationImages.map((e) => (
                <img className="counsellor-qualifications-image" src={e} />
              ))}
            </div> */}
            <ul>
              {qualifications.map((e) => (
                <li className="counsellor-qualifications-pointer font-13-800">
                  {e}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex-column-center-start meet-counsellor-policies-pointers">
          {bulletPoint(
            "Have worked or currently works in a counselling-related position"
          )}
          {bulletPoint(
            "Must abide by the Singapore Association for Counselling code of ethics"
          )}
          {bulletPoint(
            "Hold an undergraduate / postgraduate degree in Counselling, Psychology, Social Work or its equivalent"
          )}
          {bulletPoint(
            "Depression, Anxiety, Relationships, LGBTQIA+ and more..."
          )}
        </div>
      </div>

      {featuresInstitutes()}
    </div>
  );
};

const bulletPoint = (text) => {
  const publicDir = process.env.PUBLIC_URL;
  const circledTick = publicDir + "/assets/images/counsellor-quotes/tick.png";

  return (
    <div className="flex-row-start-center pointer-wrapper">
      <img
        className="flex-column-center-center pointer-tick"
        src={circledTick}
      />
      <div className="pointer-desc bullet-point">{text}</div>
    </div>
  );
};

const featuresInstitutes = () => {
  const publicDir = process.env.PUBLIC_URL;
  const jamescook =
    publicDir + "/assets/images/meet-counsellor/featured/jamescook.png";
  const feiyue =
    publicDir + "/assets/images/meet-counsellor/featured/feiyue.png";
  const imh = publicDir + "/assets/images/meet-counsellor/featured/imh.jpg";
  const kings = publicDir + "/assets/images/meet-counsellor/featured/kings.png";
  const ntu = publicDir + "/assets/images/meet-counsellor/featured/ntu.jpg";
  const nus = publicDir + "/assets/images/meet-counsellor/featured/nus.jpg";
  const featured = [jamescook, feiyue, imh, kings, ntu, nus];

  return (
    <div className="flex-column-center-center featured-wrapper">
      <div style={{
        textAlign: "center"
      }} className="font-24-900">and others who come from...</div>
      <div className="flex-row-space-around-center featured-institutes-wrapper">
        {featured.map((e) => (
          <img src={e} className="featured-image" />
        ))}
      </div>
    </div>
  );
};

export default MeetCounsellors;
