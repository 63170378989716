import React, { useEffect, useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import "../../../../utils/layout.css";
import "../../../../utils/constants.css";
import AppStoreButtons from "../appStorebuttons/AppStoreButtons"
import Button from "../../../../components/button/Button.js";

import "./pricing.css";

const Pricing = (props) => {
  const publicDir = process.env.PUBLIC_URL;
  const appStore = publicDir + "/assets/images/introduction/appstore.png";
  const playStore = publicDir + "/assets/images/introduction/playStore.png";
  const bitlyLinks = {
    listener: "https://bit.ly/3iqtGG2",
    session: "https://bit.ly/3AlCuDm",
    unlimited: "https://bit.ly/3AkHiJ8",
  };

  return (
    <div id={props.id} className="flex-column-center-center pricing-wrapper">
      <text className="font-24-900">Ready to get started?</text>
      <text className="font-18-500" style={{ textAlign: "center" }}>
        We offer comprehensive mental health support to meet all your needs.
      </text>
      <div className="flex-row-center-start pricing-tile-row-wrapper">
        {SmallPricingTile(
          "Listener",
          ["Share your problems with an active listener"],
          "Free",
          "",
          "Start Listener Plan",
          ""
        )}
        {BigPricingTile(
          "Standard",
          [
            "1-1 Counsellor Support",
            "Unlimited messages",
            "Responds daily (5 days a week)",
          ],
          "$168",
          "/ 4 weeks",
          "Start Standard Plan",
          ""
        )}
        
        {SmallPricingTile(
          "Starter",
          [
            "1-1 Counsellor Support",
            "No messaging limit",
            "Inclusive of listener",
          ],
          "$53",
          "/ week",
          "Start Starter Plan",
          ""
        )}
      </div>
      <div className="flex-column-start-center pricing-tile-wrapper-long">
      <div className="font-22-900 pricing-tile-header">{`~60% off for Students/NSFs on our Standard plan.`}</div>
      <div className="font-18-500 pricing-tile-desc-long">
        Sign up with your <b>.edu</b> email address or show us your 11B to receive your discount automatically.
      </div>
      <text className="pricing-tile-price">
        <text className="font-25-800">{`$67`}</text> {`/ 4 weeks`}
      </text>
    </div>
    
      <AppStoreButtons/>
    </div>
  );
};

const SmallPricingTile = (
  header,
  desc,
  price,
  price_interval,
  buttonText,
  buttonLink
) => {
  return (
    <div className="flex-column-start-center pricing-tile-wrapper-small">
      <div className="font-22-900 pricing-tile-header">{header}</div>
      <div className="font-18-500 flex-column-center-center pricing-tile-desc">
        {desc.map((e) => (
          <div>{e}</div>
        ))}
      </div>
      <text className="pricing-tile-price">
        <text className="font-25-800">{price}</text> {price_interval}
      </text>
    </div>
  );
};

const BigPricingTile = (
  header,
  desc,
  price,
  price_interval,
  buttonText,
  buttonLink
) => {
  const publicDir = process.env.PUBLIC_URL;
  const tick = publicDir + "/assets/images/pricing-diagram/tick.png";
  return (
    <div className="flex-column-start-center pricing-tile-wrapper-big">
      <div className="font-22-900 pricing-tile-header-big">{header}</div>
      <div className="font-18-500 flex-column-center-center pricing-tile-desc-big">
        {desc.map((e) => (
          <div>{e}</div>
        ))}
      </div>
      <text className="pricing-tile-price">
        <text className="font-25-800">{price}</text> {price_interval}
      </text>
      <img className="tick-mark" src={tick} />
    </div>
  );
};

export default Pricing;
