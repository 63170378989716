import React, { createContext, useState, useContext, useEffect } from "react";
import { ping } from "../services/users";
const AdminContext = createContext();
const UpdateAdminContext = createContext();

// use context hooks
export function useAdminContext() {
  return useContext(AdminContext);
}

export function useUpdateAdminContext() {
  return useContext(UpdateAdminContext);
}

// provider wrapper
export default function AdminContextProvider({ children }) {
  const [token, setToken] = useState(null);

  function setData(new_token) {
    setToken(new_token);
  }

  return (
    <AdminContext.Provider value={{ token: token}}>
      <UpdateAdminContext.Provider value={setData}>
        {children}
      </UpdateAdminContext.Provider>
    </AdminContext.Provider>
  );
}
