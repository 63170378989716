import React, { useEffect, useState, useCallback } from "react";
import { Select } from "antd";
import debounce from "lodash.debounce";
import { useAdminContext } from "../../../providers/AdminContext";

import "../../../utils/layout.css";
import "../../../utils/constants.css";
import "./matchPatients.css";

const { Option } = Select;

const SearchUsersInput = (props) => {
  let { placeholder, fetchDataFunc, setSelectedValue } = props;
  const adminContext = useAdminContext();
  let [data, setData] = useState([]);
  let [isLoading, setLoading] = useState(true);
  let [isEnd, setEnd] = useState(false);
  let [searchText, setSearchText] = useState(null);
  let [limit, setLimit] = useState(5);
  const paginateInterval = 5;

  useEffect(() => {
    fetchDataFunc(searchText, limit, adminContext.token)
      .then((res) => {
        data = res.data;
        if (data.success) {
          setData(data.users);
          setLoading(false);
        }
      })
      .catch((e) => {
        if(e.response.status === 401){
          window.location = "/admin"
        }
      });
  }, []);

  const debouncedSearch = useCallback(
    debounce((nextValue) => {
      setLoading(true);
      fetchDataFunc(nextValue, limit, adminContext.token)
        .then((res) => {
          const data = res.data
          if (data.success) {
            setData(data.users);
            setLoading(false);
          } else {
            window.location = "/admin"
          }
        })
        .catch((e) =>{
          if(e.response.status === 401){
            window.location = "/admin"
          }  
        });
    }, 200),
    []
  );

  const handleSearch = (value) => {
    setSearchText(value);
    if (value.trim().length > 0) {
      debouncedSearch(value);
    } else {
      debouncedSearch(null);
    }
  };

  const handleChange = (userId) => {
    let selectedUser = data.filter((e) => e.id == userId);
    setSelectedValue(selectedUser[0]);
    setSearchText(null);
  };

  const handleScroll = (e) => {
    e.persist();
    let target = e.target;
    const scrollHeight = target.scrollHeight
    // target.scrollTop + target.offsetHeight
    if (
      !isEnd &&
      !isLoading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      setLoading(true);
      fetchDataFunc(searchText, limit + paginateInterval, adminContext.token)
        .then((res) => {
          const resData = res.data
          if (resData.success) {
            if(resData.users.length > 0){
              let currentData = [...data, ...resData.users]
              currentData = currentData.filter((e,index,self)=>index === self.findIndex(t=>t.id==e.id))
              setData(currentData);
              setLoading(false);
              setLimit(limit + paginateInterval);
            }else{
              setEnd(true)
              setLoading(false);
            }
            
          } else {
            window.location = "/admin"
          }
        })
        .catch((e) =>{
          if(e.response.status === 401){
            window.location = "/admin"
          }
        });
    }
  };

  return (
    <div className="flex-column-center-center">
      <Select
        showSearch
        searchValue={searchText}
        style={{ width: 200 }}
        placeholder={placeholder}
        onSearch={handleSearch}
        onChange={handleChange}
        loading={isLoading}
        filterOption={false}
        defaultActiveFirstOption={false}
        onPopupScroll={handleScroll}
        listHeight={120}
      >
        {data.map((user) => (
          <Option
            key={user.id}
            value={user.id}
          >{`${user["first_name"]} ${user["last_name"]} (${user["id"]})`}</Option>
        ))}
      </Select>
    </div>
  );
};

export default SearchUsersInput;
