import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../../utils/layout.css";
import "../../../../utils/constants.css";
import "./recommendedReading.css";

const RecommendedReading = (props) => {
  let [posts, setPosts] = useState([]);

  useEffect(async () => {
    axios
      .get(
        "https://blog.heysteady.com/ghost/api/v4/content/posts/?key=37418b549fc2db2ae12053cb77"
      )
      .then((res) => {
        setPosts(res.data["posts"]);
      })
      .catch((e) => e);
  }, []);

  return (
    <div
      id={props.id && props.id}
      className="flex-column-start-center recommended-readings-wrapper"
    >
      <div className="recommended-readings-header font-24-900">
        Recommended Readings
      </div>
      <div className="flex-row-start-start blog-wrapper">
        {posts && posts.length > 0 ? (
          posts.map((e) => (
            <a
              href={e.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex-column-start-center blog-tile-wrapper"
            >
              <img className="blog-tile-image" src={e.feature_image} />
              <div className="flex-row-start-center blog-tile-header font-20-500 ">
                {" "}
                {e.title}{" "}
              </div>
              <div className="flex-row-start-start blog-tile-desc font-14-500">
                {" "}
                {e.custom_excerpt}{" "}
              </div>
            </a>
          ))
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default RecommendedReading;
