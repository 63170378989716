import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminContextProvider, { useAdminContext, useUpdateAdminContext }  from "./providers/AdminContext";
import React, { useEffect } from "react";
import Home from './pages/home/Home.js'
import Men from './pages/men/Men.js'
import Auth from './pages/auth/Auth.js';
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy.js";
import TermsOfUse from "./pages/termsOfUse/TermsOfUse"
import Payment from './pages/payment/Payment.js';
import MatchPatients from './pages/admin/matchPatients/MatchPatients.js';
import AdminLogin from "./pages/admin/login/AdminLogin";
import TherapistBilling from "./pages/admin/therapistBilling/TherapistBilling.js";
import { ping } from "./services/users";

import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Switch>
          <Route exact path="/auth" component={Auth} />
          <Route exact path="/" component={Home} />
          <Route exact path="/for-men" component={Men} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-of-use" component={TermsOfUse} />
          <Route exact path="/payment" component={Payment} />
          <AdminContextProvider>
            <Route exact path="/admin"  component={AdminLogin} />
            <ProtectedRoute exact path="/admin/match-patients" component={MatchPatients} />
            <ProtectedRoute exact path="/admin/therapist-billing" component={TherapistBilling} />
          </AdminContextProvider>
          
      </Switch>
    </BrowserRouter>
  );
}

function ProtectedRoute({ component: Component, ...restOfProps }){
  const adminContext = useAdminContext();
  const setToken = useUpdateAdminContext();

  let [isLoading, setLoading] = React.useState(true)
  
  useEffect(()=>{
    ping().then((res)=>{
      
      setToken(localStorage.getItem("token"))
      setLoading(false)
      return
    }).catch(e=>{
      setLoading(false)
    })
  },[])

  if(!isLoading){
    return(
      <Route
         {...restOfProps}
         render={(props) =>
           adminContext.token != null && adminContext.token.length > 0  ? <Component {...props} /> : <Redirect to="/admin" />
         }
       />)
  }else{
    return <></>
  }
  
  
}

export default App;
