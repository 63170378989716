import React, { useEffect, useState } from "react";

import "../../../../utils/layout.css";
import "../../../../utils/constants.css";

import "./features.css";

const Features = (props) => {
  const publicDir = process.env.PUBLIC_URL;
  const phone1 = publicDir + "/assets/images/features/phone1.png";
  const phone2 = publicDir + "/assets/images/features/phone2.png";
  const phone3 = publicDir + "/assets/images/features/phone3.png";

  return (
    <div
      id={props.id && props.id}
      className="flex-row-center-start features-wrapper"
    >
      {section(
        phone1,
        "Pen your thoughts to build a more ",
        "mindful",
        " life"
      )}
      {section(
        phone2,
        "",
        "On-the-go",
        " Messaging with counsellors on your phone."
      )}
      {section(phone3, "", "Smart personalization", " to support your needs")}
    </div>
  );
};

const section = (imagePath, headerOne, markedHeader, headerTwo) => {
  return (
    <div className="feature-section-wrapper flex-column-start-center">
      <text className="section-text font-24-800">
        {headerOne}
        <mark>{markedHeader}</mark>
        {headerTwo}
      </text>
      <img src={imagePath} className="section-image" />
    </div>
  );
};
export default Features;
