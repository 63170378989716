import React from "react";

import "../../utils/layout.css";
import "../../utils/constants.css";

const Men = () => {

  return (
    <div className="fullscreen flex-column-start-center">
        For Men
    </div>
  );
};

export default Men;
