import React, { useEffect, useState } from "react";

import "../../../../utils/layout.css";
import "../../../../utils/constants.css";

import "./journeyProcess.css";

import {
  ClipboardCheck,
  RelationOneToOne,
  PlayerPlay,
} from "tabler-icons-react";

const JourneyProcess = (props) => {
  const publicDir = process.env.PUBLIC_URL;
  const arrow = publicDir + "/assets/images/journey/arrow.png";
  const clipboard = publicDir + "/assets/images/journey/clipboard.png";
  const phone = publicDir + "/assets/images/journey/phone.png";
  const plant = publicDir + "/assets/images/journey/plant.png";

  return (
    <div
      id={props.id && props.id}
      className="flex-column-center-center journey-wrapper"
    >
      <div className="journey-header font-24-900">How it works</div>
      <div className="flex-row-center-start section-row-wrapper">
        {journeySection(
          clipboard,
          "Brief Assessment",
          "Answer a few questions so we can personalize the experience for you."
        )}
        <div className="arrow-wrapper flex-column-center-center">
          <img className="arrow" src={arrow} />
        </div>

        {journeySection(
          phone,
          "Connect with a therapist",
          "Get matched with the most suitable therapist for you. Change at any time at no extra cost."
        )}
        <div className="arrow-wrapper flex-column-center-center">
          <img className="arrow" src={arrow} />
        </div>
        {journeySection(
          plant,
          "Start therapy",
          "Begin your journey towards a better you."
        )}
      </div>
    </div>
  );
};

const journeySection = (icon, header, subheader) => {
  return (
    <div className="flex-column-start-center section-wrapper">
      <img className="section-icon" src={icon} />
      <div className="section-header font-24-800">{header}</div>
      <div className="section-subheader font-18-500">{subheader}</div>
    </div>
  );
};
export default JourneyProcess;
