import React, { useEffect, useState } from "react";
import { Descriptions, Collapse, Checkbox } from "antd";
import { getUserOnboardingData } from "../../../services/users";
import { useAdminContext } from "../../../providers/AdminContext";

import "../../../utils/layout.css";
import "../../../utils/constants.css";
const { Panel } = Collapse;

const PatientCardInfo = (props) => {
  let { user } = props;
  const adminContext = useAdminContext();
  let [onboardData, setOnboardData] = useState({
    concerns: [],
    declarations: [],
    form: [],
  });

  useEffect(() => {
    if (user != null) {
      getUserOnboardingData(user.id, adminContext.token)
        .then((e) => {
          let data = e.data;
          if (data.success) {
            setOnboardData(data);
          } else {
            props.history.push("/admin");
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            window.location = "/admin";
          }
        });
    }
  }, [user]);
  return (
    <Collapse style={{ width: "100%" }}>
      <Panel
        header={
          user != null
            ? `${user["first_name"]}'s Onboarding Info`
            : "No Patient Info"
        }
        key="1"
      >
        {user != null && Object.keys(onboardData).length > 0 ? (
          <>
            <Descriptions
              bordered
              column={1}
              style={{ margin: "10px" }}
              title="User data"
            >
              {Object.keys(user).map((key) => (
                <Descriptions.Item key={key} label={key}>
                  {user[key]}
                </Descriptions.Item>
              ))}
            </Descriptions>
            <Descriptions
              title="Concerns"
              bordered
              column={3}
              style={{ margin: "10px" }}
            >
              {onboardData["concerns"].map((concern) => (
                <Descriptions.Item key={concern}>{concern}</Descriptions.Item>
              ))}
            </Descriptions>
            <Descriptions
              title="Declarations"
              bordered
              column={1}
              style={{ margin: "10px" }}
            >
              {Array.isArray(onboardData["declarations"]) &&
              onboardData["declarations"] !== null
                ? onboardData["declarations"].map((declaration) => {
                  
                  return(
                    <Descriptions.Item key={Object.keys(declaration)[0]}>
                      <Checkbox checked={Object.values(declaration)[0]}>
                        {Object.keys(declaration)[0]}
                      </Checkbox>
                    </Descriptions.Item>
                  )})
                : Object.keys(onboardData["declarations"]).map(
                    (declarationKey) => (
                      <Descriptions.Item key={declarationKey}>
                        <Checkbox
                          checked={onboardData["declarations"][declarationKey]}
                        >
                          {declarationKey}
                        </Checkbox>
                      </Descriptions.Item>
                    )
                  )}
            </Descriptions>
            {onboardData["form"].map((page) => (
              <Descriptions
                key={page}
                title={page["title"]}
                layout="vertical"
                bordered
                column={1}
                style={{ margin: "10px" }}
              >
                {page["sections"].map((section) => (
                  <Descriptions.Item
                    key={section["header"]}
                    label={section["header"]}
                  >
                    <div className="flex-column-center-start">
                      {section["widgets"].map((widget) =>
                        widget["type"] == "TextField"
                          ? widget["body"][0]["value"]
                          : widget["body"].map((data) => (
                              <Checkbox
                                key={data["key"]}
                                checked={data["value"]}
                                style={{ margin: "5px" }}
                              >
                                {data["key"]}
                              </Checkbox>
                            ))
                      )}
                    </div>
                  </Descriptions.Item>
                ))}
              </Descriptions>
            ))}
          </>
        ) : (
          <></>
        )}
      </Panel>
    </Collapse>
  );
};

export default PatientCardInfo;
