import React, { useEffect, useState, useRef, useMemo } from "react";
import { MenuOutlined } from "@ant-design/icons";

import "../../utils/layout.css";
import "../../utils/constants.css";

import Button from "../button/Button.js";

import "./navbar.css";

const Navbar = (props) => {
  const publicDir = process.env.PUBLIC_URL;
  const logoImagePath = publicDir + "/assets/images/logo.png";

  const navbarRef = useRef();

  let [isMobileNavbarOpen, setMobileNavBar] = useState(false);

  const onBurgerClick = (e) => {
    e.preventDefault();
    setMobileNavBar(!isMobileNavbarOpen);
  };

  const handleClickOutsideNavbar = (e) => {
    if (!navbarRef.current.contains(e.target) && isMobileNavbarOpen) {
      setMobileNavBar(false);
    }
  };

  useEffect(() => {
    if (isMobileNavbarOpen) {
      document.addEventListener("mousedown", handleClickOutsideNavbar);
      return () => {
        document.removeEventListener("mousedown", handleClickOutsideNavbar);
      };
    }
  }, [isMobileNavbarOpen]);

  return (
    <>
      <div className="flex-row-center-center">
        <Button
          className="burger-button"
          onClick={onBurgerClick}
          icon={<MenuOutlined />}
        />
      </div>
      <nav
        className={`flex-row-space-between-center navbar ${
          isMobileNavbarOpen ? "navbar-mobile-enter" : "navbar-mobile-exit"
        } font-24-500`}
        ref={navbarRef}
      >
        <a className="steady-logo" href="./">
          <img style={{ width: "100%" }} src={logoImagePath} />
        </a>
        <div className="flex-row-center-center navbar-links font-18-900">
          <a href="#how-does-it-work">How Does It Work?</a>
          <a href="#pricing">Pricing</a>
          <a href="#pricing">
            <Button
              className="get-access-button font-18-900"
              text={"Get started"}
            />
          </a>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

// transform: translateY(0px) translateX(0px); transition: transform 400ms ease 0s;
